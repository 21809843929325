import React from 'react';
import PropTypes from 'prop-types';
import { Document } from '@react-pdf/renderer';
import ProjectCoverPage from './components/v2/ProjectCoverPage';
import ConsultantCalendarPage from './components/v2/ConsultantCalendarPage';
import MissionDetailPage from './components/v2/MissionDetailPage';
import SignaturePage from './components/v2/SignaturePage';

const TimeSheetPDFTemplateV2 = ({ projectData, selectedDate }) => {
  const totalPages = 3 + projectData.missions.length; // Cover + Calendar + Missions + Signature
  console.log('projectData2', projectData);

  // Transform missions data to include all required fields
  const missionsWithData = Object.entries(projectData.missions).map(([id, mission]) => ({
    id,
    name: mission.name,
    totalDays: mission.totalDays,
    days: mission.days,
    consultants: mission.consultants,
    consultantDays: mission.consultantDays || [], // Add consultantDays with default empty array
  }));

  return (
    <Document>
      {/* Cover Page with Global Overview */}
      <ProjectCoverPage
        projectData={projectData}
        selectedDate={selectedDate}
        pageNumber={`1/${totalPages}`}
      />

      <ConsultantCalendarPage
        projectData={projectData}
        selectedDate={selectedDate}
        pageNumber={`2/${totalPages}`}
      />

      {/* Mission Detail Pages */}
      {missionsWithData.map((mission, index) => (
        <MissionDetailPage
          key={mission.id}
          mission={mission}
          projectData={projectData}
          selectedDate={selectedDate}
          pageNumber={`${index + 3}/${totalPages}`}
        />
      ))}

      {/* Signature Page */}
      <SignaturePage
        projectData={projectData}
        selectedDate={selectedDate}
        pageNumber={`${totalPages}/${totalPages}`}
      />
    </Document>
  );
};

TimeSheetPDFTemplateV2.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    missions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        consultants: PropTypes.arrayOf(
          PropTypes.shape({
            email: PropTypes.string.isRequired,
            totalDays: PropTypes.number.isRequired,
          })
        ).isRequired,
        consultantDays: PropTypes.arrayOf(
          PropTypes.shape({
            email: PropTypes.string.isRequired,
            days: PropTypes.arrayOf(
              PropTypes.shape({
                date: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
              })
            ).isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default TimeSheetPDFTemplateV2;
