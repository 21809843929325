import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatDateWithCapitalizedMonth } from '../utils/dateUtils';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 20,
    left: '20mm',
    right: '20mm',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 8,
    color: '#999999',
    fontStyle: 'italic',
  },
  bottomRow: {
    fontSize: 8,
    color: '#999999',
    fontStyle: 'italic',
    marginTop: 4,
  },
  confidentialityNotice: {
    flex: 1,
  },
  pageNumber: {
    textAlign: 'right',
    width: '50px',
  },
});

const Footer = ({ projectName, projectCode, selectedDate, pageNumber }) => {
  const { i18n } = useTranslation();

  return (
    <View style={styles.footer} fixed>
      <View style={styles.topRow}>
        <Text style={styles.confidentialityNotice}>
          © BOMZAI – Document confidentiel – Diffusion restreinte
        </Text>
        <Text style={styles.pageNumber}>{pageNumber}</Text>
      </View>
      <Text style={styles.bottomRow}>
        {projectName} - {formatDateWithCapitalizedMonth(selectedDate, 'MMMM yyyy', i18n.language)}
      </Text>
    </View>
  );
};

Footer.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectCode: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default Footer;
