import React from 'react';
import PropTypes from 'prop-types';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { Document, Page, StyleSheet, View, Image, Text } from '@react-pdf/renderer';

// Import all components
import CoverPage from './components/CoverPage';
import Footer from './components/Footer';
import ConsultantSummaryTable from './components/tables/ConsultantSummaryTable';
import ConsultantTimesheet from './components/tables/ConsultantTimesheet';
import SignaturePage from './components/SignaturePage';
import WeeklyCalendarView from './components/WeeklyCalendarView';

// Add logo import
import BomzaiLogo from '../../../assets/images/bomzai-logo-black.png';
const styles = StyleSheet.create({
  page: {
    padding: '20mm',
    paddingTop: '40mm',
    backgroundColor: 'white',
    position: 'relative',
    minHeight: '297mm',
  },
  consultantPairContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  consultantContainer: {
    flex: 1,
    marginBottom: '10mm',
  },
  firstConsultant: {
    marginBottom: '20mm',
  },
  consultantSeparator: {
    borderBottom: '1pt solid #000',
    width: '100%',
    marginVertical: '10mm',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  summaryPage: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5mm',
    paddingBottom: '20mm',
  },
  summaryTable: {
    marginBottom: '10mm',
  },
  weeklyView: {
    marginTop: '10mm',
    flex: 1,
    minHeight: '40mm',
  },
  banner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '30mm',
    backgroundColor: '#f5f5f5',
    padding: '10mm 20mm',
    zIndex: 1,
  },
  logo: {
    position: 'absolute',
    top: '10mm',
    right: '20mm',
    width: '30mm',
    height: 'auto',
    zIndex: 2,
  },
  missionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10mm',
  },
  missionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  consultantList: {
    marginTop: '10mm',
  },
  consultantItem: {
    marginBottom: '5mm',
  },
});

const aggregateConsultantData = timesheets => {
  const aggregatedDays = {};

  console.log('Aggregating timesheet data:', timesheets);

  timesheets.forEach(({ days }) => {
    console.log('Processing timesheet days:', days);

    days.forEach(({ date, value }) => {
      console.log('Processing day:', { date, value });

      if (!aggregatedDays[date]) {
        aggregatedDays[date] = 0;
      }
      aggregatedDays[date] += value;
    });
  });

  const result = Object.entries(aggregatedDays).map(([date, value]) => ({ date, value }));
  console.log('Aggregated result:', result);

  return result;
};

const MissionSummaryPage = ({
  mission,
  period,
  pageNumber,
  totalPages,
  projectData,
  selectedDate,
}) => {
  // Convert mission data to match global weekly format
  const standardizedConsultant = {
    id: mission.id,
    userEmail: mission.name,
    totalDays: mission.totalDays,
    days: mission.days.map(day => ({
      date: day.date,
      value: Number(day.value),
    })),
  };

  // Create mission-specific project data for the summary table
  const missionProjectData = {
    ...projectData,
    // Transform consultants array into timesheets format expected by ConsultantSummaryTable
    timesheets: mission.consultants.map(consultant => ({
      id: consultant.email,
      userEmail: consultant.email,
      totalDays: consultant.totalDays,
      // Map mission days to consultant days, filtering by consultant
      days: mission.days.map(day => ({
        date: day.date,
        value: Number(day.value),
      })),
    })),
  };
  console.log('MissionSummaryPage - Mission:', mission, missionProjectData);

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.missionContainer}>
        <Text style={styles.missionTitle}>{mission.name}</Text>
        <View style={styles.summaryPage}>
          <View style={styles.summaryTable}>
            <ConsultantSummaryTable projectData={missionProjectData} />
          </View>
        </View>

        <WeeklyCalendarView
          consultant={standardizedConsultant}
          period={period}
          consultantDays={standardizedConsultant.days}
        />
      </View>
      <Footer
        projectName={projectData.projectName}
        projectCode={projectData.projectCode}
        selectedDate={selectedDate}
        pageNumber={`${pageNumber}/${totalPages}`}
      />
    </Page>
  );
};

MissionSummaryPage.propTypes = {
  mission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    consultants: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
      })
    ).isRequired,
    totalDays: PropTypes.number.isRequired,
    days: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  period: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  pageNumber: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

const TimeSheetPDFTemplate = ({ projectData, selectedDate }) => {
  // Create period object from selectedDate
  const period = {
    start: startOfMonth(selectedDate),
    end: endOfMonth(selectedDate),
  };

  console.log('TimeSheetPDFTemplate - Full Input:', {
    projectData,
    period,
    selectedDate,
  });

  const missions = projectData.missions || [];
  console.log('Rendering PDF with missions:', missions);

  // Calculate total pages: Cover + Global Summary + Mission Summaries + Consultant pairs + Signatures
  const totalPages = 2 + missions.length + Math.ceil(projectData.timesheets.length / 2) + 1;

  const systemConsultant = {
    id: 'system',
    userEmail: 'System',
    totalDays: projectData.timesheets.reduce((sum, { totalDays }) => sum + totalDays, 0),
    days: aggregateConsultantData(projectData.timesheets),
  };

  // Format the filename for the document title - remove the year suffix
  const month = format(selectedDate, 'yyyyMM');
  const projectName = projectData.projectName.replace(/[^a-zA-Z0-9-_]/g, '_');
  const projectCode = projectData.projectCode || 'NO_CODE';
  const documentTitle = `${month}-BOMZAI-${projectName}-${projectCode}`;

  console.log('Global weekly format:', {
    systemConsultant: {
      id: systemConsultant.id,
      userEmail: systemConsultant.userEmail,
      totalDays: systemConsultant.totalDays,
      days: systemConsultant.days,
    },
  });

  // Process missions to match global format
  const processedMissions = projectData.missions.map(mission => ({
    id: mission.id || mission.name, // Fallback to name if id not present
    name: mission.name,
    totalDays: mission.totalDays,
    days: mission.days.map(day => ({
      date: day.date,
      value: Number(day.value),
    })),
    consultants: mission.consultants,
  }));

  // Log the standardized format
  console.log('Standardized mission format:', processedMissions[0]);

  return (
    <Document title={documentTitle} author="BOMZAI" creator="BOMZAI" producer="BOMZAI">
      {/* Cover Page (Page 1) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <Image style={styles.logo} src={BomzaiLogo} />
        <CoverPage projectData={projectData} period={period} />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`1/${totalPages}`}
        />
      </Page>

      {/* Summary Page (Page 2) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <Image style={styles.logo} src={BomzaiLogo} />
        <View style={styles.summaryPage}>
          <View style={styles.summaryTable}>
            <ConsultantSummaryTable projectData={projectData} />
          </View>
        </View>
        <WeeklyCalendarView
          consultant={systemConsultant}
          period={period}
          consultantDays={systemConsultant.days}
        />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`2/${totalPages}`}
        />
      </Page>

      {/* Mission Summary Pages */}
      {processedMissions.map((mission, index) => (
        <MissionSummaryPage
          key={mission.id}
          mission={mission}
          period={period}
          pageNumber={`${index + 3}/${totalPages}`}
          totalPages={totalPages}
          projectData={projectData}
          selectedDate={selectedDate}
        />
      ))}

      {/* Consultant Pair Pages */}
      {projectData.timesheets
        .reduce((pairs, timesheet, index) => {
          const pairIndex = Math.floor(index / 2);
          if (!pairs[pairIndex]) {
            pairs[pairIndex] = [];
          }
          pairs[pairIndex].push(timesheet);
          return pairs;
        }, [])
        .map((pair, pageIndex) => (
          <Page key={`pair-${pageIndex}`} size="A4" style={styles.page} wrap={false}>
            <View style={styles.banner} />
            <Image style={styles.logo} src={BomzaiLogo} />
            <View style={styles.consultantPairContainer}>
              {pair.map((timesheet, index) => (
                <React.Fragment key={timesheet.id}>
                  <View style={[styles.consultantContainer]}>
                    <ConsultantTimesheet
                      consultant={timesheet}
                      period={period}
                      projectData={projectData}
                      showKPIs={false}
                    />
                  </View>
                  {index === 0 && pair.length > 1 && <View style={styles.consultantSeparator} />}
                </React.Fragment>
              ))}
            </View>
            <Footer
              projectName={projectData.projectName}
              projectCode={projectData.projectCode || '-'}
              selectedDate={selectedDate}
              pageNumber={`${pageIndex + 3 + missions.length}/${totalPages}`}
            />
          </Page>
        ))}

      {/* Signature Page (Last Page) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.banner} />
        <Image style={styles.logo} src={BomzaiLogo} />
        <SignaturePage />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`${totalPages}/${totalPages}`}
        />
      </Page>
    </Document>
  );
};

TimeSheetPDFTemplate.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    contractRef: PropTypes.string,
    orderNumber: PropTypes.string,
    totalUO: PropTypes.number,
    totalTickets: PropTypes.number,
    totalDays: PropTypes.number.isRequired,
    missions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ).isRequired,
        consultants: PropTypes.arrayOf(
          PropTypes.shape({
            email: PropTypes.string.isRequired,
            totalDays: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userEmail: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default TimeSheetPDFTemplate;
