import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MonthNavigator from '../common/MonthNavigator';
import { useTimesheets } from '../../hooks/useTimesheets';
import { parseISO, format } from 'date-fns';
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { pdf } from '@react-pdf/renderer';
import TimeSheetPDFTemplate from './pdf/TimeSheetPDFTemplate';
import TimeSheetPDFTemplateV2 from './pdf/TimeSheetPDFTemplateV2';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PrintV2Icon from '@mui/icons-material/Print';
import VisibilityV2Icon from '@mui/icons-material/Visibility';

const TimeSheetPrint = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize selectedDate from URL or current date
  const initializeDate = () => {
    const monthParam = searchParams.get('month');
    if (monthParam) {
      try {
        // Parse YYYY-MM format to Date
        console.log('Month parameter:', monthParam);
        return parseISO(`${monthParam}-02`);
      } catch (error) {
        console.error('Invalid month parameter:', error);
        return new Date();
      }
    }
    return new Date();
  };

  const [selectedDate, setSelectedDate] = useState(initializeDate);
  const { timesheets, loading } = useTimesheets(selectedDate);
  const [projectTimesheets, setProjectTimesheets] = useState({});
  const [expandedProjects, setExpandedProjects] = useState({});
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [filter, setFilter] = useState('all'); // 'all', 'invoicable', 'non-invoicable'

  // Update URL when month changes
  const handleMonthChange = newDate => {
    const monthString = format(newDate, 'yyyy-MM');
    setSearchParams({ month: monthString });
    setSelectedDate(newDate);
  };

  // Toggle expansion state for a project
  const handleExpandClick = projectId => {
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  // Add filter handler
  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };
  // Filter projects
  const filteredProjects = useMemo(() => {
    return Object.entries(projectTimesheets).filter(([_, projectData]) => {
      if (filter === 'invoicable') return projectData.invoicable;
      if (filter === 'non-invoicable') return !projectData.invoicable;
      return true; // 'all'
    });
  }, [projectTimesheets, filter]);

  // Add this helper function at the top
  const getMissionDisplayName = missionId => {
    // Extract the actual mission name from the ID (after the '+' character)
    const parts = missionId.split('_');
    return parts[parts.length - 1] || missionId;
  };

  // Rename the main processing function
  const processTimesheetsData = useCallback(async timesheets => {
    const processedData = await timesheets.reduce(async (promise, timesheet) => {
      const acc = await promise;

      // Process each project in the timesheet
      for (const project of timesheet.projects || []) {
        if (!project?.id) continue;

        // Initialize project if not exists
        if (!acc[project.id]) {
          const projectDoc = await getDoc(doc(db, 'projects', project.id));
          const projectData = projectDoc.data();
          acc[project.id] = {
            projectName: project.name,
            projectCode: projectData.code,
            timesheets: [],
            totalDays: 0,
            invoicable: projectData.invoicable,
            missions: {}, // Add missions object
          };
        }

        // Get all entries for this project's missions
        const projectTimeEntries = {};
        let projectTotalDays = 0;

        // Process missions from timeEntries
        project.missions.forEach(missionId => {
          const missionEntries = timesheet.timeEntries[missionId] || {};

          // Initialize mission if not exists
          if (!acc[project.id].missions[missionId]) {
            acc[project.id].missions[missionId] = {
              name: getMissionDisplayName(missionId),
              totalDays: 0,
              days: [],
              consultants: [],
              consultantDays: [], // Array of consultant objects with their days
            };
          }

          const mission = acc[project.id].missions[missionId];

          // Add consultant if not already present
          const consultantIndex = mission.consultants.findIndex(
            c => c.email === timesheet.userEmail
          );
          if (consultantIndex === -1) {
            mission.consultants.push({
              email: timesheet.userEmail,
              totalDays: 0,
            });
          }

          // Process mission days
          Object.entries(missionEntries).forEach(([date, value]) => {
            const numValue = Number(value);
            // Update mission totals
            mission.totalDays += numValue;
            const consultant = mission.consultants.find(c => c.email === timesheet.userEmail);
            if (consultant) {
              consultant.totalDays += numValue;
            }

            // Add day to mission
            const existingDay = mission.days.find(d => d.date === date);
            if (existingDay) {
              existingDay.value += numValue;
            } else {
              mission.days.push({ date, value: numValue });
            }

            // Find or create consultant entry in consultantDays
            let consultantEntry = mission.consultantDays.find(
              entry => entry.email === timesheet.userEmail
            );
            if (!consultantEntry) {
              consultantEntry = {
                email: timesheet.userEmail,
                days: [],
              };
              mission.consultantDays.push({
                email: timesheet.userEmail,
                days: [],
              });
            }

            // Add or update day for this consultant
            const existingDayConsultant = consultantEntry.days.find(day => day.date === date);
            if (existingDayConsultant) {
              existingDayConsultant.value = numValue;
            } else {
              consultantEntry.days.push({
                date,
                value: numValue,
              });
            }

            // Update project totals
            projectTimeEntries[date] = (projectTimeEntries[date] || 0) + numValue;
            projectTotalDays += numValue;
          });

          // In processTimesheetsData function, after processing a mission
          console.log('Mission data format:', {
            mission: {
              id: missionId,
              name: mission.name,
              totalDays: mission.totalDays,
              days: mission.days,
              consultants: mission.consultants,
            },
          });
        });

        // Only add timesheet if total days > 0
        if (projectTotalDays > 0) {
          acc[project.id].timesheets.push({
            id: timesheet.id,
            userEmail: timesheet.userEmail,
            status: timesheet.status,
            totalDays: projectTotalDays,
            days: Object.entries(projectTimeEntries).map(([date, value]) => ({
              date,
              value,
            })),
          });
          acc[project.id].totalDays += projectTotalDays;
        }
      }
      return acc;
    }, Promise.resolve({}));

    // Convert missions object to array and sort everything
    Object.values(processedData).forEach(project => {
      project.timesheets.sort((a, b) => a.userEmail.localeCompare(b.userEmail));
      project.missions = Object.values(project.missions);
      project.missions.sort((a, b) => a.name.localeCompare(b.name));
      project.missions.forEach(mission => {
        mission.days.sort((a, b) => a.date.localeCompare(b.date));
        mission.consultants.sort((a, b) => a.email.localeCompare(b.email));
      });
    });

    console.log('Processed data with missions:', processedData);
    setProjectTimesheets(processedData); // Make sure we update the state
  }, []);

  useEffect(() => {
    if (timesheets && Array.isArray(timesheets)) {
      console.log('timesheets', timesheets);
      processTimesheetsData(timesheets).catch(console.error);
    }
  }, [timesheets, processTimesheetsData]);

  const handlePrintPDF = async projectId => {
    setIsPdfGenerating(true);
    try {
      console.log('Generating PDF for project:', projectId);
      const projectData = projectTimesheets[projectId];

      // Format the filename first - remove the year suffix
      const month = format(selectedDate, 'yyyyMM');
      const projectName = projectData.projectName.replace(/[^a-zA-Z0-9-_]/g, '_');
      const projectCode = projectData.projectCode || 'NO_CODE';
      const filename = `${month}-BOMZAI-${projectName}-${projectCode}.pdf`;

      console.log('Will generate PDF with filename:', filename, projectData);

      const PDFDocument = (
        <TimeSheetPDFTemplate projectData={projectData} selectedDate={selectedDate} />
      );

      const blob = await pdf(PDFDocument).toBlob();
      console.log('PDF blob generated:', blob.size, 'bytes');

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // Use the clean filename

      console.log('Starting download with filename:', link.download);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        URL.revokeObjectURL(url);
        console.log('URL revoked after download');
      }, 1000);
    } catch (error) {
      console.error('Error generating PDF:', error, error.stack);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handleViewTimesheet = timesheetId => {
    navigate(`/timesheet/edit/${timesheetId}`);
  };

  const handlePreviewClick = async projectId => {
    try {
      console.log('Previewing PDF for project:', projectId, projectTimesheets[projectId]);

      // Create missions array from project data
      const projectData = projectTimesheets[projectId];
      const missions = Object.entries(projectData.missions || {}).map(([id, mission]) => ({
        id,
        name: mission.name,
        totalDays: mission.totalDays,
        days: mission.days,
        consultants: mission.consultants,
      }));

      // Create PDF document
      const doc = await pdf(
        <TimeSheetPDFTemplate
          projectData={{
            ...projectData,
            missions,
          }}
          selectedDate={selectedDate}
        />
      ).toBlob();

      // Create URL and open in new tab
      const url = URL.createObjectURL(doc);
      window.open(url, '_blank');

      // Clean up the URL after a delay
      setTimeout(() => URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error('Error generating PDF preview:', error);
    }
  };

  const handlePrintPDFV2 = async projectId => {
    setIsPdfGenerating(true);
    try {
      console.log('Generating PDF V2 for project:', projectId);
      const projectData = projectTimesheets[projectId];

      const PDFDocument = (
        <TimeSheetPDFTemplateV2 projectData={projectData} selectedDate={selectedDate} />
      );

      const blob = await pdf(PDFDocument).toBlob();
      console.log('PDF blob generated:', blob.size, 'bytes');

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${format(selectedDate, 'yyyyMM')}-BOMZAI-${projectData.projectName.replace(/[^a-zA-Z0-9-_]/g, '_')}-${projectData.projectCode || 'NO_CODE'}.pdf`;

      console.log('Starting download with filename:', link.download);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        URL.revokeObjectURL(url);
        console.log('URL revoked after download');
      }, 1000);
    } catch (error) {
      console.error('Error generating PDF:', error, error.stack);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  const handlePreviewClickV2 = async projectId => {
    try {
      console.log('Previewing PDF V2 for project:', projectId);
      const projectData = projectTimesheets[projectId];

      // Create missions array from project data
      const missions = Object.entries(projectData.missions || {}).map(([id, mission]) => ({
        id,
        name: mission.name,
        totalDays: mission.totalDays,
        days: mission.days,
        consultants: mission.consultants,
        consultantDays: mission.consultantDays || [], // Add consultantDays to the missions data
      }));

      // Create PDF document
      const doc = await pdf(
        <TimeSheetPDFTemplateV2
          projectData={{
            ...projectData,
            missions,
          }}
          selectedDate={selectedDate}
        />
      ).toBlob();

      // Create URL and open in new tab
      const url = URL.createObjectURL(doc);
      window.open(url, '_blank');

      // Clean up the URL after a delay
      setTimeout(() => URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error('Error generating PDF preview:', error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Typography>{t('common.loading')}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">{t('timesheet.print.title')}</Typography>
      </Box>

      <MonthNavigator selectedDate={selectedDate} onChange={handleMonthChange} />

      {/* Add Filter Controls */}
      <Box sx={{ mb: 2, mt: 2 }}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          aria-label="project filter"
        >
          <ToggleButton value="all">{t('timesheet.print.filters.all')}</ToggleButton>
          <ToggleButton value="invoicable">{t('timesheet.print.filters.invoicable')}</ToggleButton>
          <ToggleButton value="non-invoicable">
            {t('timesheet.print.filters.non_invoicable')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Use filteredProjects instead of Object.entries(projectTimesheets) */}
      {filteredProjects.map(([projectId, projectData]) => {
        if (!projectData.timesheets || projectData.timesheets.length === 0) return null;

        return (
          <Card key={projectId} sx={{ mt: 2, p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={5}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => handleExpandClick(projectId)}
                    aria-expanded={expandedProjects[projectId]}
                    aria-label="show more"
                    sx={{ mr: 1 }}
                  >
                    {expandedProjects[projectId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Box>
                    <Typography variant="h6">
                      {projectData.projectCode} - {projectData.projectName}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" color="textSecondary">
                        {t('timesheet.print.timesheetCount', {
                          count: projectData.timesheets.length,
                        })}
                      </Typography>
                      {projectData.invoicable && (
                        <MonetizationOnIcon
                          color="primary"
                          fontSize="small"
                          titleAccess={t('timesheet.print.project_type.invoicable')}
                        />
                      )}
                    </Box>
                    <Typography variant="body2">
                      {t('timesheet.print.totalDays', {
                        days: projectData.totalDays,
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  startIcon={<PrintIcon />}
                  onClick={() => handlePrintPDF(projectId)}
                  sx={{ mr: 2 }}
                  disabled={isPdfGenerating}
                >
                  {isPdfGenerating ? t('common.loading') : t('timesheet.print.printPDF')}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handlePreviewClick(projectId)}
                  sx={{ mr: 2 }}
                >
                  {t('timesheet.print.preview')}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PrintV2Icon />}
                  onClick={() => handlePrintPDFV2(projectId)}
                  sx={{ mr: 2 }}
                  disabled={isPdfGenerating}
                >
                  {isPdfGenerating ? t('common.loading') : t('timesheet.print.printPDFV2')}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<VisibilityV2Icon />}
                  onClick={() => handlePreviewClickV2(projectId)}
                >
                  {t('timesheet.print.previewV2')}
                </Button>
              </Grid>
            </Grid>

            <Collapse in={expandedProjects[projectId]} timeout="auto" unmountOnExit>
              <List sx={{ mt: 2 }}>
                {projectData.timesheets.map((timesheet, index) => (
                  <React.Fragment key={timesheet.id}>
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="view"
                          onClick={() => handleViewTimesheet(timesheet.id)}
                          sx={{ mr: 1 }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={timesheet.userEmail}
                        secondary={
                          <>
                            <Typography component="span" variant="body2">
                              {t('timesheet.form.status.' + timesheet.status.toLowerCase())} -
                            </Typography>
                            <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                              {t('timesheet.print.totalDays', { days: timesheet.totalDays })}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    {index < projectData.timesheets.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </Card>
        );
      })}
    </Container>
  );
};

export default TimeSheetPrint;
