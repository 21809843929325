import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import BomzaiLogo from '../../../../../assets/images/bomzai-logo-black.png';

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 30,
    left: 30,
    right: 30,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 120,
    height: 'auto',
  },
});

const Header = () => (
  <View style={styles.header}>
    <Image style={styles.logo} src={BomzaiLogo} />
  </View>
);

export default Header;
