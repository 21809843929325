/**
 * Extracts a formatted name from an email address
 * Example: "jean-emmanuel.orfevre@bomzai.fr" -> "Jean-Emmanuel Orfevre"
 * @param {string} email - The email address to parse
 * @returns {string} The formatted name
 */
export const getNameFromEmail = email => {
  if (!email) return '';

  // Get the part before the @ symbol
  const namePart = email.split('@')[0];

  // Split by dots or hyphens
  const parts = namePart.split(/[.-]/);

  // Capitalize each part and handle special cases
  const formattedParts = parts.map(part => {
    // Skip empty parts
    if (!part) return '';

    // Capitalize first letter of each part
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });

  // Join with appropriate spacing
  // If original had hyphens, preserve them in the output
  let result = '';
  for (let i = 0; i < parts.length; i++) {
    if (i > 0) {
      // If original had a hyphen, keep it
      if (namePart.charAt(namePart.indexOf(parts[i]) - 1) === '-') {
        result += '-';
      } else {
        result += ' ';
      }
    }
    result += formattedParts[i];
  }

  return result;
};
