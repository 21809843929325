import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { eachDayOfInterval, startOfMonth, endOfMonth, format, isWeekend } from 'date-fns';
import { getNameFromEmail } from '../../../../../utils/nameUtils';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#dee2e6',
  },
  headerCell: {
    width: 25,
    height: 25,
    padding: 2,
    fontSize: 8,
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
  },
  dayCell: {
    width: 25,
    height: 25,
    padding: 2,
    fontSize: 8,
    textAlign: 'center',
  },
  weekendCell: {
    backgroundColor: '#f8f9fa',
  },
  valueCell: {
    backgroundColor: '#e8f5e9',
  },
  nameCell: {
    width: 120,
    padding: 4,
    fontSize: 8,
    borderRightWidth: 1,
    borderRightColor: '#dee2e6',
  },
  totalCell: {
    width: 40,
    padding: 4,
    fontSize: 8,
    textAlign: 'center',
    borderLeftWidth: 1,
    borderLeftColor: '#dee2e6',
  },
});

const MonthCalendarTable = ({ month, data }) => {
  const { t } = useTranslation();
  const days = eachDayOfInterval({
    start: startOfMonth(month),
    end: endOfMonth(month),
  });

  return (
    <View style={styles.container}>
      {/* Header row with dates */}
      <View style={styles.row}>
        <Text style={styles.nameCell}>{t('timesheet.pdf.consultant')}</Text>
        {days.map(day => (
          <Text
            key={format(day, 'yyyy-MM-dd')}
            style={[styles.headerCell, isWeekend(day) && styles.weekendCell]}
          >
            {format(day, 'd')}
          </Text>
        ))}
        <Text style={styles.totalCell}>Total</Text>
      </View>

      {/* Data rows */}
      {data.map((row, index) => (
        <View key={index} style={styles.row}>
          <Text style={styles.nameCell}>{getNameFromEmail(row.name)}</Text>
          {days.map(day => {
            const dateStr = format(day, 'yyyy-MM-dd');
            const value = row.days[dateStr] || 0;
            return (
              <Text
                key={dateStr}
                style={[
                  styles.dayCell,
                  isWeekend(day) && styles.weekendCell,
                  value > 0 && styles.valueCell,
                ]}
              >
                {value || ''}
              </Text>
            );
          })}
          <Text style={styles.totalCell}>{row.total}</Text>
        </View>
      ))}
    </View>
  );
};

MonthCalendarTable.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      days: PropTypes.object.isRequired, // { "2024-03-01": 1, "2024-03-02": 0.5, ... }
      total: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default MonthCalendarTable;
