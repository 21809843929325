import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import Footer from './Footer';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import { getNameFromEmail } from '../../../../../utils/nameUtils';
import MonthCalendarTable from './MonthCalendarTable';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 60,
    paddingTop: 100,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    color: '#1a237e',
    borderBottom: '1pt solid #dee2e6',
    paddingBottom: 8,
  },
  section: {
    marginBottom: 15,
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 4,
    border: '1pt solid #dee2e6',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#1a237e',
    borderBottom: '1pt solid #dee2e6',
    paddingBottom: 8,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: '40%',
    fontSize: 10,
  },
  value: {
    flex: 1,
    fontSize: 10,
  },
});

const MissionDetailPage = ({ mission, projectData, selectedDate, pageNumber }) => {
  const { t } = useTranslation();

  // Transform consultantDays into the format expected by MonthCalendarTable
  const calendarData = (mission.consultantDays || []).map(consultant => ({
    name: consultant.email,
    days: consultant.days.reduce((acc, day) => {
      acc[day.date] = day.value;
      return acc;
    }, {}),
    total: mission.consultants.find(c => c.email === consultant.email)?.totalDays || 0,
  }));

  return (
    <Page size="A4" style={styles.page}>
      <Header />
      <Text style={styles.title}>{mission.name}</Text>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.mission_details')}</Text>
        <View style={styles.row}>
          <Text style={styles.label}>{t('timesheet.pdf.total_days')}:</Text>
          <Text style={styles.value}>{mission.totalDays}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>{t('timesheet.pdf.total_consultants')}:</Text>
          <Text style={styles.value}>{mission.consultants?.length || 0}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.consultants_calendar')}</Text>
        <MonthCalendarTable month={selectedDate} data={calendarData} />
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.consultants_details')}</Text>
        {mission.consultants?.map((consultant, index) => (
          <View key={index} style={styles.row}>
            <Text style={styles.label}>{getNameFromEmail(consultant.email)}:</Text>
            <Text style={styles.value}>
              {consultant.totalDays} {t('timesheet.pdf.days')}
            </Text>
          </View>
        ))}
      </View>

      <Footer
        projectName={projectData.projectName}
        projectCode={projectData.projectCode}
        selectedDate={selectedDate}
        pageNumber={pageNumber}
      />
    </Page>
  );
};

MissionDetailPage.propTypes = {
  mission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    totalDays: PropTypes.number.isRequired,
    consultants: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
      })
    ).isRequired,
    consultantDays: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ),
  }).isRequired,
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default MissionDetailPage;
