import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import Footer from './Footer';
import GlobalOverview from './GlobalOverview';
import Header from './Header';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 60,
    paddingTop: 100, // Make room for header
  },
  content: {
    flex: 1,
  },
  titleSection: {
    marginBottom: 40,
    borderBottom: '2pt solid #666',
    paddingBottom: 20,
  },
  title: {
    fontSize: 32,
    marginBottom: 10,
    color: '#1a237e', // Dark blue color
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 20,
    color: '#666',
  },
  projectCode: {
    fontSize: 16,
    color: '#666',
    marginTop: 5,
  },
});

const ProjectCoverPage = ({ projectData, selectedDate, pageNumber }) => (
  <Page size="A4" style={styles.page}>
    <Header />

    <View style={styles.content}>
      <View style={styles.titleSection}>
        <Text style={styles.title}>{projectData.projectName}</Text>
        {projectData.projectCode && projectData.projectCode !== projectData.projectName && (
          <Text style={styles.projectCode}>{projectData.projectCode}</Text>
        )}
      </View>

      <GlobalOverview projectData={projectData} selectedDate={selectedDate} />
    </View>

    <Footer
      projectName={projectData.projectName}
      projectCode={projectData.projectCode}
      selectedDate={selectedDate}
      pageNumber={pageNumber}
    />
  </Page>
);

ProjectCoverPage.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default ProjectCoverPage;
