import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import MonthCalendarTable from './MonthCalendarTable';
import Header from './Header';
import Footer from './Footer';
import { getNameFromEmail } from '../../../../../utils/nameUtils';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 60,
    paddingTop: 100, // Make room for header
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    color: '#1a237e',
    borderBottom: '1pt solid #dee2e6',
    paddingBottom: 8,
  },
  calendarContainer: {
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 4,
    border: '1pt solid #dee2e6',
  },
});

const ConsultantCalendarPage = ({ projectData, selectedDate, pageNumber }) => {
  const { t } = useTranslation();

  // Prepare data for the calendar table
  const consultantDays = {};
  projectData.timesheets?.forEach(timesheet => {
    if (!consultantDays[timesheet.userEmail]) {
      consultantDays[timesheet.userEmail] = {
        name: timesheet.userEmail,
        days: {},
        total: timesheet.totalDays || 0,
      };
    }

    // Add days from timesheet
    timesheet.days?.forEach(day => {
      consultantDays[timesheet.userEmail].days[day.date] = day.value;
    });
  });

  // Sort consultants by their formatted names
  const sortedConsultants = Object.values(consultantDays).sort((a, b) => {
    const nameA = getNameFromEmail(a.name);
    const nameB = getNameFromEmail(b.name);
    return nameA.localeCompare(nameB);
  });

  return (
    <Page size="A4" style={styles.page}>
      <Header />

      <View style={styles.content}>
        <Text style={styles.title}>{t('timesheet.pdf.consultants_calendar')}</Text>
        <View style={styles.calendarContainer}>
          <MonthCalendarTable month={selectedDate} data={sortedConsultants} />
        </View>
      </View>

      <Footer
        projectName={projectData.projectName}
        projectCode={projectData.projectCode}
        selectedDate={selectedDate}
        pageNumber={pageNumber}
      />
    </Page>
  );
};

ConsultantCalendarPage.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        userEmail: PropTypes.string.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ),
        totalDays: PropTypes.number,
      })
    ),
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default ConsultantCalendarPage;
