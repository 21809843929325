/**
 * @component TimeSheetReview
 * @description Container component for reviewing and validating timesheets.
 * Manages the approval/rejection workflow and data fetching.
 */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, query, getDocs, orderBy, where, limit, startAfter } from 'firebase/firestore';
import { db, functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { useToast } from '../../contexts/toast';
import TimeSheetReviewView from './TimeSheetReviewView';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { startOfMonth, format, parse, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth';
import { useCoachees } from '../../hooks/useCoachees';
import {
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  AssignmentLate as AssignmentLateIcon,
} from '@mui/icons-material';

const getRelevantUsers = (activeUsers, user, coachees, searchQuery) => {
  return activeUsers.filter(activeUser => {
    // First filter by user role/permissions
    if (user?.role === 'rh' || user?.role === 'superadmin') {
      // Then apply search filter if exists
      if (searchQuery) {
        return activeUser.email.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return true;
    }

    const isCoachee = coachees.some(
      coachee => coachee?.email?.toLowerCase() === activeUser?.email?.toLowerCase()
    );
    const isSelf = activeUser?.email?.toLowerCase() === user?.email?.toLowerCase();
    const hasPermission = isSelf || isCoachee;

    // Apply search filter if exists
    if (searchQuery) {
      return hasPermission && activeUser.email.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return hasPermission;
  });
};

const normalizeEmail = email => {
  // First handle _at_ format
  const withoutAt = email.toLowerCase().replace('_at_bomzai.fr', '').replace('_at_bomz.ai', '');

  // Then handle @ format if needed
  return withoutAt.split('@')[0];
};

// Main component for timesheet review functionality
const TimeSheetReview = () => {
  // Move searchParams initialization to the top
  const [searchParams, setSearchParams] = useSearchParams();

  // Store mutable references to avoid dependency cycles
  const loadingRef = useRef(false);
  const pageRef = useRef(0);
  const cursorsRef = useRef({});

  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status') || 'ALL');
  const [statusManagerFilter, setStatusManagerFilter] = useState('ALL');
  const [searchQuery, setSearchQuery] = useState(searchParams.get('email') || '');
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const monthParam = searchParams.get('month');
    if (monthParam) {
      const parsedDate = parse(monthParam, 'yyyy-MM', new Date());
      return isValid(parsedDate) ? startOfMonth(parsedDate) : startOfMonth(new Date());
    }
    return startOfMonth(new Date());
  });
  const [hasMore, setHasMore] = useState(true);
  const [showAllTimesheets, setShowAllTimesheets] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [usersWithoutTimesheet, setUsersWithoutTimesheet] = useState([]);
  const [reminderCounts, setReminderCounts] = useState({});
  const [allTimesheets, setAllTimesheets] = useState([]);
  const [lastReminderSentAt, setLastReminderSentAt] = useState({});

  const { showToast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const coachees = useCoachees(user?.email);

  // Memoize the monthString to prevent recalculations
  const monthString = useMemo(() => format(selectedMonth, 'yyyy-MM'), [selectedMonth]);

  // Update URL when filters change
  const updateUrlParams = useCallback(
    params => {
      const newParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  // Memoize the query constraints with fewer dependencies
  const queryConstraints = useMemo(() => {
    const constraints = [];

    if (!showAllTimesheets) {
      constraints.push(where('month', '==', monthString));
    }

    if (statusFilter !== 'ALL') {
      constraints.push(where('status', '==', statusFilter));
    }

    if (searchQuery) {
      const searchEnd = searchQuery + '\uf8ff';
      constraints.push(where('userEmail', '>=', searchQuery));
      constraints.push(where('userEmail', '<=', searchEnd));
    }

    constraints.push(orderBy('userEmail'));
    constraints.push(orderBy('month', 'desc'));
    constraints.push(limit(rowsPerPage));

    return constraints;
  }, [showAllTimesheets, monthString, statusFilter, rowsPerPage, searchQuery]);

  // 1. Stabilize user data with useMemo
  const userData = useMemo(
    () => ({
      email: user?.email,
      role: user?.role,
      coachees: user?.coachees,
    }),
    [user?.email, user?.role, user?.coachees]
  );

  // 2. Stabilize fetchTimesheets with useCallback and fewer dependencies
  const fetchTimesheets = useCallback(
    async (pageNum, shouldReset = false) => {
      if (loadingRef.current) {
        console.log('Already loading, skipping fetch');
        return;
      }

      try {
        loadingRef.current = true;
        setLoading(true);

        const baseQuery = collection(db, 'timesheets');
        const monthOnlyConstraints = [where('month', '==', monthString), orderBy('userEmail')];
        const fullQuery = query(baseQuery, ...monthOnlyConstraints);
        const fullSnapshot = await getDocs(fullQuery);

        const allTimesheetData = fullSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate ? data.createdAt.toDate() : null,
            validatedAt: data.validatedAt?.toDate ? data.validatedAt.toDate() : null,
          };
        });

        setAllTimesheets(allTimesheetData);

        // Add pagination to query constraints
        const paginatedConstraints = [
          ...queryConstraints,
          limit(rowsPerPage),
          ...(cursorsRef.current[pageNum] ? [startAfter(cursorsRef.current[pageNum])] : []),
        ];

        const filteredQuery = query(baseQuery, ...paginatedConstraints);
        const filteredSnapshot = await getDocs(filteredQuery);

        // Store the last document for next page
        if (filteredSnapshot.docs.length > 0) {
          cursorsRef.current[pageNum + 1] = filteredSnapshot.docs[filteredSnapshot.docs.length - 1];
        }

        const filteredData = filteredSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate ? data.createdAt.toDate() : null,
            validatedAt: data.validatedAt?.toDate ? data.validatedAt.toDate() : null,
            isOwner: data.userId === userData.email?.toLowerCase().replace('@', '_at_'),
            isCoachee: userData.role === 'superadmin' || userData.role === 'rh',
            isCoach:
              userData.role === 'superadmin' ||
              userData.role === 'rh' ||
              (userData.coachees && userData.coachees.includes(data.userId)),
          };
        });

        setTimesheets(filteredData);
        setHasMore(filteredSnapshot.docs.length >= rowsPerPage);
      } catch (error) {
        console.error('Error in fetchTimesheets:', error);
        showToast('Error loading timesheets', 'error');
      } finally {
        loadingRef.current = false;
        setLoading(false);
      }
    },
    [queryConstraints, rowsPerPage, showToast, userData, monthString]
  );

  // Update refs when state changes
  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  // Handle page change
  const handlePageChange = useCallback(
    (event, newPage) => {
      if (loadingRef.current) return;

      setPage(newPage);
      fetchTimesheets(newPage, newPage === 0);
    },
    [fetchTimesheets]
  );

  // Reset everything when filters change
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loadingRef.current) {
        console.log('Filter effect triggered');
        setPage(0);
        cursorsRef.current = {};
        fetchTimesheets(0, true);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [statusFilter, monthString, showAllTimesheets, fetchTimesheets, searchQuery, rowsPerPage]);

  const handleViewTimesheet = useCallback(
    timesheet => {
      navigate(`/timesheet/edit/${timesheet.id}`, {
        state: {
          initialData: timesheet,
          userEmail: timesheet.userEmail,
        },
      });
    },
    [navigate]
  );

  const handleRowsPerPageChange = useCallback(event => {
    if (loadingRef.current) return;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    cursorsRef.current = {};
  }, []);

  const handleStatusFilterChange = useCallback(
    newStatus => {
      const status = newStatus.target.value;
      setStatusFilter(status);
      updateUrlParams({ status });
    },
    [updateUrlParams]
  );

  const handleStatusManagerFilterChange = useCallback(newStatus => {
    setStatusManagerFilter(newStatus);
  }, []);

  const handleSearchQueryChange = useCallback(
    query => {
      const email = query.trim().toLowerCase();
      setSearchQuery(email);
      updateUrlParams({ email });
    },
    [updateUrlParams]
  );

  const handleMonthChange = useCallback(
    newDate => {
      if (newDate) {
        setSelectedMonth(startOfMonth(newDate));
        updateUrlParams({
          month: format(startOfMonth(newDate), 'yyyy-MM'),
        });
      }
    },
    [updateUrlParams]
  );

  const handleShowAllTimesheetsChange = useCallback(checked => {
    setShowAllTimesheets(checked);
    setPage(0);
    cursorsRef.current = {};
  }, []);

  // Fetch active users
  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('isActive', '==', true));
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActiveUsers(users);
      } catch (error) {
        console.error('Error fetching active users:', error);
        showToast('Error loading users', 'error');
      }
    };

    fetchActiveUsers();
  }, [showToast]);

  // 3. Stabilize getRelevantUsers with useMemo
  const relevantUsers = useMemo(
    () => getRelevantUsers(activeUsers, userData, coachees, searchQuery),
    [activeUsers, userData, coachees, searchQuery]
  );

  // 4. Modify the missing timesheets effect to use memoized values
  useEffect(() => {
    if (!showAllTimesheets && selectedMonth && activeUsers.length > 0) {
      const timesheetEmails = new Set(allTimesheets.map(t => normalizeEmail(t.userEmail)));

      const missingUsers = relevantUsers.filter(user => {
        const normalizedEmail = normalizeEmail(user.id);
        return !timesheetEmails.has(normalizedEmail);
      });

      setUsersWithoutTimesheet(missingUsers);
    } else {
      setUsersWithoutTimesheet([]);
    }
  }, [showAllTimesheets, selectedMonth, allTimesheets, relevantUsers, activeUsers.length]);

  // 5. Stabilize fetchReminderCounts
  const fetchReminderCounts = useCallback(async () => {
    if (!selectedMonth || showAllTimesheets) return;

    try {
      const monthStr = format(selectedMonth, 'yyyy-MM');
      const emailLogsRef = collection(db, 'email_logs');
      const q = query(
        emailLogsRef,
        where('type', '==', 'REMINDER'),
        where('month', '==', monthStr),
        orderBy('createdAt', 'desc')
      );

      const snapshot = await getDocs(q);
      const counts = {};
      const lastSentAt = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        const emailId = data.userEmailId;
        counts[emailId] = (counts[emailId] || 0) + 1;
        if (!lastSentAt[emailId] || data.sentAt > lastSentAt[emailId]) {
          lastSentAt[emailId] = data.sentAt;
        }
      });

      setReminderCounts(counts);
      setLastReminderSentAt(lastSentAt);
    } catch (error) {
      console.error('Error fetching reminder counts:', error);
    }
  }, [selectedMonth, showAllTimesheets]);

  // Fetch reminder counts when month changes or after sending a reminder
  useEffect(() => {
    fetchReminderCounts();
  }, [fetchReminderCounts]);

  const handleSendReminder = async email => {
    try {
      const monthString = format(selectedMonth, 'yyyy-MM');
      const sendTimesheetReminder = httpsCallable(functions, 'sendTimesheetReminder');

      const timesheetUrl = `${window.location.origin}/timesheet/new?month=${monthString}&userEmail=${email}`;

      await sendTimesheetReminder({
        email,
        month: monthString,
        name: email.split('@')[0],
        timesheetUrl,
      });

      showToast(t('timesheet.messages.reminder_sent'), 'success');
      // Refresh reminder counts after sending
      fetchReminderCounts();
    } catch (error) {
      console.error('Error sending reminder:', error);
      showToast(t('timesheet.messages.reminder_error'), 'error');
    }
  };

  const handleCreateTimesheet = email => {
    const monthString = format(selectedMonth, 'yyyy-MM');
    navigate(`/timesheet/new?month=${monthString}&userEmail=${email}`);
  };

  const stats = useMemo(() => {
    if (!activeUsers || !allTimesheets) {
      return {
        totalUsers: 0,
        timesheetsFound: 0,
        timesheetsMissing: 0,
      };
    }

    const relevantUsers = getRelevantUsers(activeUsers, user, coachees, searchQuery);
    const usersWithTimesheet = new Set(allTimesheets.map(t => normalizeEmail(t.userEmail)));
    const missingUsers = relevantUsers.filter(user => {
      const normalizedEmail = normalizeEmail(user.id);
      return !usersWithTimesheet.has(normalizedEmail);
    });

    return {
      totalUsers: relevantUsers.length,
      timesheetsFound: usersWithTimesheet.size,
      timesheetsMissing: missingUsers.length,
    };
  }, [activeUsers, allTimesheets, user, coachees, searchQuery]);

  return (
    <TimeSheetReviewView
      timesheets={timesheets}
      loading={loading}
      page={page}
      rowsPerPage={rowsPerPage}
      statusFilter={statusFilter}
      statusManagerFilter={statusManagerFilter}
      searchQuery={searchQuery}
      selectedMonth={selectedMonth}
      showAllTimesheets={showAllTimesheets}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onStatusFilterChange={handleStatusFilterChange}
      onStatusManagerFilterChange={handleStatusManagerFilterChange}
      onSearchQueryChange={handleSearchQueryChange}
      onMonthChange={handleMonthChange}
      onViewTimesheet={handleViewTimesheet}
      onShowAllTimesheetsChange={handleShowAllTimesheetsChange}
      hasMore={hasMore}
      isEmpty={!loading && timesheets.length === 0}
      usersWithoutTimesheet={usersWithoutTimesheet}
      missingTimesheetsCount={usersWithoutTimesheet.length}
      onSendReminder={handleSendReminder}
      onCreateTimesheet={handleCreateTimesheet}
      reminderCounts={reminderCounts}
      lastReminderSentAt={lastReminderSentAt}
      isCoach={coachees.length > 0}
      userRole={user?.role}
      stats={stats}
      statIcons={{
        totalUsers: <PeopleIcon />,
        timesheetsFound: <AssignmentIcon />,
        timesheetsMissing: <AssignmentLateIcon />,
      }}
    />
  );
};

export default TimeSheetReview;
