import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import Footer from './Footer';
import Header from './Header';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 60,
    paddingTop: 100,
  },
  title: {
    fontSize: 18,
    marginBottom: 40,
  },
  signatureArea: {
    marginTop: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signature: {
    width: '45%',
  },
  signatureTitle: {
    fontSize: 12,
    marginBottom: 40,
  },
  signatureLine: {
    borderBottom: '1pt solid black',
    marginTop: 60,
  },
});

const SignaturePage = ({ projectData, selectedDate, pageNumber }) => (
  <Page size="A4" style={styles.page}>
    <Header />
    <Text style={styles.title}>Signatures</Text>

    <View style={styles.signatureArea}>
      <View style={styles.signature}>
        <Text style={styles.signatureTitle}>Client</Text>
        <View style={styles.signatureLine} />
      </View>
      <View style={styles.signature}>
        <Text style={styles.signatureTitle}>Bomzai</Text>
        <View style={styles.signatureLine} />
      </View>
    </View>

    <Footer
      projectName={projectData.projectName}
      projectCode={projectData.projectCode}
      selectedDate={selectedDate}
      pageNumber={pageNumber}
    />
  </Page>
);

SignaturePage.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default SignaturePage;
