import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    backgroundColor: '#f8f9fa',
    padding: 20,
    borderRadius: 4,
  },
  section: {
    marginBottom: 15,
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 4,
    border: '1pt solid #dee2e6',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#1a237e',
    borderBottom: '1pt solid #dee2e6',
    paddingBottom: 8,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: '40%',
    fontSize: 10,
  },
  value: {
    flex: 1,
    fontSize: 10,
  },
  table: {
    marginTop: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#1a237e',
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#1a237e',
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  tableCell: {
    fontSize: 10,
  },
  col1: { width: '40%' },
  col2: { width: '30%' },
  col3: { width: '30%' },
});

const GlobalOverview = ({ projectData, selectedDate }) => {
  const { t } = useTranslation();
  const totalConsultants = new Set(
    projectData.missions?.flatMap(mission => mission.consultants?.map(c => c.email)) || []
  ).size;

  // Prepare data for the calendar table
  const consultantDays = {};
  projectData.missions?.forEach(mission => {
    mission.consultants?.forEach(consultant => {
      if (!consultantDays[consultant.email]) {
        consultantDays[consultant.email] = {
          name: consultant.email,
          days: {},
          total: 0,
        };
      }

      // Add days for this mission to consultant's total
      mission.days?.forEach(day => {
        const current = consultantDays[consultant.email].days[day.date] || 0;
        consultantDays[consultant.email].days[day.date] = current + day.value;
        consultantDays[consultant.email].total += day.value;
      });
    });
  });

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.project_overview')}</Text>
        <View style={styles.row}>
          <Text style={styles.label}>{t('timesheet.pdf.period')}:</Text>
          <Text style={styles.value}>{format(selectedDate, 'MMMM yyyy', { locale: fr })}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Total Days:</Text>
          <Text style={styles.value}>{projectData.totalDays || 0}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Total Consultants:</Text>
          <Text style={styles.value}>{totalConsultants}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('timesheet.pdf.missions_summary')}</Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableHeaderCell, styles.col1]}>Mission</Text>
            <Text style={[styles.tableHeaderCell, styles.col2]}>Days</Text>
            <Text style={[styles.tableHeaderCell, styles.col3]}>Consultants</Text>
          </View>
          {projectData.missions?.map((mission, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.col1]}>{mission.name}</Text>
              <Text style={[styles.tableCell, styles.col2]}>{mission.totalDays}</Text>
              <Text style={[styles.tableCell, styles.col3]}>
                {mission.consultants?.length || 0}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

GlobalOverview.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    totalDays: PropTypes.number,
    missions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        consultants: PropTypes.arrayOf(
          PropTypes.shape({
            email: PropTypes.string.isRequired,
            totalDays: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default GlobalOverview;
