import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import WeeklyCalendarView from '../WeeklyCalendarView';

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 20,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: '12pt',
    border: '1pt solid #e0e0e0',
  },
  title: {
    fontSize: 18,
    color: '#1a237e',
    marginBottom: 12,
    borderBottom: '1pt solid #e0e0e0',
    paddingBottom: 8,
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  tableContainer: {
    flex: 1,
  },
  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 4,
    backgroundColor: '#ffffff',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    borderBottomStyle: 'solid',
    minHeight: 28,
    alignItems: 'center',
  },
  headerRow: {
    backgroundColor: '#f5f5f5',
    minHeight: 32,
  },
  weekendRow: {
    backgroundColor: '#fafafa',
  },
  tableCell: {
    padding: '6pt',
  },
  dateCell: {
    flex: 7,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    borderRightStyle: 'solid',
  },
  valueCell: {
    flex: 3,
    textAlign: 'center',
  },
  headerText: {
    fontSize: 10,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  dateText: {
    fontSize: 9,
    color: '#424242',
  },
  weekendText: {
    color: '#757575',
  },
  valueText: {
    fontSize: 9,
    color: '#424242',
  },
  totalContainer: {
    marginTop: 10,
    textAlign: 'right',
    paddingRight: 4,
  },
  totalText: {
    fontSize: 11,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    marginTop: 10,
  },
  infoItem: {
    flex: 1,
    minWidth: '45%',
  },
  label: {
    fontSize: 9,
    color: '#666666',
    marginBottom: 2,
  },
  value: {
    fontSize: 11,
    color: '#1a237e',
  },
  largeValue: {
    fontSize: 14,
    color: '#1a237e',
    fontWeight: 'bold',
  },
  calendarSection: {
    marginTop: 15,
    marginBottom: 20,
    padding: '12pt',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    border: '1pt solid #e0e0e0',
  },
  calendarTitle: {
    fontSize: 12,
    color: '#1a237e',
    marginBottom: 8,
    borderBottom: '1pt solid #e0e0e0',
    paddingBottom: 4,
  },
});

const ConsultantTimesheet = ({ consultant, period, projectData, showKPIs = false }) => {
  console.log('ConsultantTimesheet - Raw consultant data:', JSON.stringify(consultant, null, 2));
  console.log('ConsultantTimesheet - Projects data:', JSON.stringify(projectData, null, 2));

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{consultant.userEmail}</Text>
      <WeeklyCalendarView consultant={consultant} period={period} />
      {showKPIs && <View style={styles.kpiContainer}>{/* Your existing KPI content */}</View>}
    </View>
  );
};

ConsultantTimesheet.propTypes = {
  consultant: PropTypes.shape({
    userEmail: PropTypes.string.isRequired,
    totalDays: PropTypes.number.isRequired,
    days: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    timeEntries: PropTypes.objectOf(
      PropTypes.objectOf(
        PropTypes.string // values are strings like "0.5"
      )
    ),
    missions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        days: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
  }).isRequired,
  period: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  showKPIs: PropTypes.bool,
};

export default ConsultantTimesheet;
