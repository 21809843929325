import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    borderTop: '1pt solid #666',
    paddingTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 8,
    color: '#666',
  },
});

const Footer = ({ projectName, projectCode, selectedDate, pageNumber }) => (
  <View style={styles.footer}>
    <Text style={styles.text}>
      {projectName} - {projectCode}
    </Text>
    <Text style={styles.text}>{format(selectedDate, 'MMMM yyyy', { locale: fr })}</Text>
    <Text style={styles.text}>{pageNumber}</Text>
  </View>
);

Footer.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectCode: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  pageNumber: PropTypes.string.isRequired,
};

export default Footer;
